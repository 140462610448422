<template>
  <div>
    <div @click="showShare = true">
      点击显示或隐藏
    </div>
    <notification
      v-model="showShare"
      :isios="isios"
      :isandriod="isandriod"
      :andriod="andriod"
    ></notification>
  </div>
</template>

<script>
import notification from '../components/notification.vue'
export default {
  components: {
    notification
  },
  data() {
    return {
      isios: false,
      isandriod: true,
      andriod: 'https://www.baidu.com/',
      showShare: false
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
