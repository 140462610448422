<template>
  <div>
    <van-popup v-model="show" @click-overlay="clickOverlay">
      <div class="bossBox">
        <p class="verify">手机号绑定验证</p>
        <div>
          <van-cell-group>
            <van-field
              v-model="phone"
              clearable
              :border="false"
              type="number"
              maxlength="11"
              placeholder="请填写手机号"
              left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
            />
          </van-cell-group>
        </div>
        <div class="yanzheng">
          <van-cell-group class="cell">
            <van-field
              v-model="code"
              :border="false"
              placeholder="验证码"
              left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
            />
          </van-cell-group>
          <div v-show="codeShow" class="song" @click="getCode">
            获取验证码
          </div>
          <div v-show="!codeShow" class="song">
            {{ yzmTitle }}
          </div>
        </div>
        <div class="sure" @click="isTrue">下载</div>
      </div>
    </van-popup>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
  </div>
</template>

<script>
import { sendSms, bindFriend } from '../api/index'
export default {
  components: {
    't-captcha-js': {
      render(createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  props: ['isios', 'isandriod', 'andriod', 'value'],

  data() {
    return {
      show: false,
      phone: '',
      msg: '', //提示
      code: '',
      yzmTitle: '',
      titleShow: false,
      codeShow: true //图片验证
    }
  },
  watch: {
    value: {
      handler(val) {
        console.log(val, '监听')
        this.show = val
      },
      immediate: true
    }
    // isios: {
    //   handler(val) {
    //     console.log(val, '监听isios')
    //   },
    //   immediate: true
    // }
  },
  methods: {
    getCode() {
      if (!this.phone) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!reg.test(this.phone)) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      this.showCodeImg()
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha('2008794111', (coderes) => {
        console.log(coderes)
        const { randstr, ticket } = coderes
        sendSms({ phone: this.phone, type1: 1, randstr, ticket }).then(
          (res) => {
            console.log(res)
            if (res.data.code == 200) {
              let time = 60
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer)
                  this.codeShow = true
                } else {
                  this.codeShow = false
                  this.yzmTitle = time + '秒后重试'
                  time--
                }
              }, 1000)
              this.msg = '获取验证码成功'
              console.log(this.msg, 'this.msg')
              this.titleShow = true
              setTimeout(() => {
                this.titleShow = false
              }, 2000)
            } else {
              this.$toast(res.data.message)
            }
          }
        )
      })
      captcha1.show()
    },
    async isTrue() {
      console.log(this.isios, '9999查看')
      let data = {
        rid: this.$route.query.rid || '',
        uid: this.$route.query.uid,
        phone: this.phone,
        keyCode: this.code,
        store_id: this.$route.query.store_id
      }
      let res = await bindFriend(data)
      console.log(res, 'resres')
      if (res.data.code != 200) {
        this.msg = res.data.message
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      if (this.isios) {
        window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
      }
      if (this.isandriod) {
        window.location.href = this.andriod
      }
      // this.show = !this.show
    },
    // 点击遮罩层
    clickOverlay() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.bossBox {
  width: 8rem;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
  .verify {
    font-size: 0.53rem;
    color: #333;
    font-weight: 400;
    padding: 0.8rem 0 0.25rem 0;
  }
  .yanzheng {
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .song {
      width: 2.76rem;
      height: 1.15rem;
      background: #43d227;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      font-size: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fefefe;
      font-weight: 400;
    }
  }
  .sure {
    width: 4.6rem;
    height: 1.1rem;
    background: #43d227;
    font-size: 0.45rem;
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 0.4rem;
    border-radius: 2rem;
  }
}
.van-cell-group {
  margin-top: 0.2rem;
  width: 7rem;
  .van-cell {
    // width: 100%;
    height: 1.2rem;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  /deep/ .van-icon {
    font-size: 0.8rem;
    margin: 0 0.3rem 0 0.34rem;
  }

  /deep/ .van-field__body {
    font-size: 0.41rem;
  }
}
.cell {
  width: 4rem;
  /deep/ .van-icon {
    font-size: 0.65rem;
    margin: 0 0.3rem 0 0.41rem;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}
</style>
